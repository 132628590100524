import {Component, createContext, PropsWithChildren, useContext} from "react";
// @ts-ignore
import Keycloak, {KeycloakConfig} from "keycloak-js";
import {auth} from "../services/dsl-service-new/service";
import socket from "../services/dsl-service-new/socker-io";
interface ContextValues {
    keycloak: Keycloak,
    isAuthenticated?: boolean
    userInfo?: {
        email?: string,
        email_verified?: boolean
        family_name?: string
        given_name?: string
    }
}

interface Props extends PropsWithChildren {
    keycloakConf: KeycloakConfig
}

interface State {
    initialized: boolean
}


const AuthContext = createContext<ContextValues>({} as ContextValues)

class AuthProvider extends Component<Props, State>{
    state:State = {
        initialized: false
    }

    keycloak: Keycloak
    constructor(props: Props) {
        super(props);
        this.keycloak = new Keycloak(props.keycloakConf)
        this.keycloak.init({
            flow:"standard",
            useNonce:true,
            onLoad:"check-sso",
            scope:"profile",
        }).then()
    }


    componentDidMount() {
        this.keycloak.onAuthSuccess = async ()=>{


        }
        this.keycloak.onAuthRefreshSuccess= async ()=>{
            if(this.keycloak.token) {
                auth.token = this.keycloak.token
                socket.emit('auth', this.keycloak.token)
            }
        }

        this.keycloak.onTokenExpired = async ()=>{
            await this.keycloak.updateToken(5)
            if(this.keycloak.token) {
                auth.token = this.keycloak.token
                socket.emit('auth', this.keycloak.token)
            }
        }

        this.keycloak.onReady = async (authenticated:boolean)=>{
            if(authenticated) {
                await this.keycloak.loadUserInfo()
                if(this.keycloak.token) {
                    auth.token = this.keycloak.token
                    socket.emit('auth', this.keycloak.token)
                }
            }
            this.setState({initialized:true})
        }

    }


    render() {
        if(this.state.initialized) {
            return (
                <AuthContext.Provider value={{
                    keycloak: this.keycloak,
                    isAuthenticated: this.keycloak.authenticated,
                    userInfo: this.keycloak.userInfo
                }}>
                    {this.props.children}
                </AuthContext.Provider>
            )
        } else {
            return <></>;
        }
    }
}

const AuthConsumer = AuthContext.Consumer;

export const useAuth = ()=>{
    return useContext(AuthContext);
}

export {AuthProvider as Provider, AuthConsumer as Consumer}

export default AuthContext;
